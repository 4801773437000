import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "field", "checkbox", "selectAll", "bulkActions"];

  connect() {
  }

  toggleSelectOne(event) {
    const isChecked = event.target.checked;
    this.toggleBulkActionButton();
    this.updateSubmitButtonLabel();
  }

  toggleSelectAll(event) {
    const isChecked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
    this.toggleBulkActionButton();
    this.updateSubmitButtonLabel();
  }

  toggleBulkActionButton() {
    if (this.checkboxesSelected()) {
      this.submitTarget.classList.remove("hidden"); 
      this.fieldTarget.classList.remove("hidden");
      this.selectAllTarget.classList.remove("hidden");
      this.bulkActionsTarget.classList.remove("hidden");
    } else {
      this.submitTarget.classList.add("hidden");
      this.fieldTarget.classList.add("hidden");
      this.selectAllTarget.classList.add("hidden");
      this.bulkActionsTarget.classList.add("hidden");
    }
  }

  checkboxesSelected() {
    // return true if at least one checkbox has been selected using the countSelectedCheckboxes method
    return this.countSelectedCheckboxes() > 0;
  }

  countSelectedCheckboxes() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked).length;
  }

  updateSubmitButtonLabel() {
    // The label will begin with either 'Add Selected' or 'Remove Selected'
    // Insert the count of selected checkboxes in the middle of the label
    const count = this.countSelectedCheckboxes();
    const action_word = this.submitTarget.value.split(" ")[0];
    this.submitTarget.value = `${action_word} ${count} Selected`;
  }
}
